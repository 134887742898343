import React, { useState, useEffect } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Recaptcha from "react-recaptcha"
import {
  Flex,
  Input,
  Grid,
  Label,
  Box,
  Button,
  Heading,
  Text,
  Select,
} from "theme-ui"

const ContactForm = ({ selection }) => {
  const [token, setToken] = useState(null)
  const [sent, setSent] = useState(false)
  const [selectionState, setSelectionState] = useState("schedule")
  
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    setSelectionState(selection)
    console.log(selection)
  }, [selection])

  return (
    <div>
      <Formik
        initialValues={{ name: "", phone: "", email: "", reason: "", message: "" }}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": "contact",
              ...values,
              "g-recaptcha-response": token,
            }),
          })
            .then(() => {
              setSent(true)
              actions.resetForm()
            })
            .catch(() => {
              alert("Error")
            })
            .finally(() => actions.setSubmitting(false))
        }}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          const phoneRegex =
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
          const errors = {}
          if (!values.name) {
            errors.name = "Name Required"
          }
          if (!values.phone || !phoneRegex.test(values.phone)) {
            errors.phone = "Valid Phone Required"
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email = "Valid Email Required"
          }
          if (!values.reason) {
            errors.reason = "Reason Required"
          }
          if (!values.message) {
            errors.message = "Message Required"
          }
          return errors
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Flex
            as={Form}
            method="POST"
            variant="layout.form"
            data-netlify-honeypot="website"
            data-netlify-recaptcha="true"
            data-netlify="true"
            name="contact"
            onSubmit={handleSubmit}
          >
            <Input type="hidden" name="website" />
            <Input type="hidden" name="form-name" value="contact" />
            {!sent ? (
              <>
                <Heading variant="mediumAlwaysDark" className="soft-faux-font">
                  We’re social animals!
                </Heading>
                <Text as="p" variant="paragraphAlwaysWhite">
                  I want to connect with you! Use the form below to reach out—to
                  inquire about appointments and events, ask questions, share
                  your comments. We survive and thrive through connection!
                </Text>
                <Grid rows={[1]} columns={[1, 3]} gap={[3]}>
                  <Box>
                    <Label htmlFor="name">Name*</Label>
                    <Input
                      as={Field}
                      variant="input.base"
                      type="text"
                      name="name"
                      id="name"
                      required
                    />
                    <ErrorMessage name="name" />
                  </Box>
                  <Box>
                    <Label htmlFor="phone">Phone*</Label>
                    <Input
                      as={Field}
                      variant="input.base"
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="(555)555-5555"
                      required
                    />
                    <ErrorMessage name="phone" />
                  </Box>
                  <Box>
                    <Label htmlFor="email">Email*</Label>
                    <Input
                      as={Field}
                      variant="input.base"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="john@weirevolving.com"
                      required
                    />
                    <ErrorMessage name="email" />
                  </Box>
                </Grid>

                <Grid
                  variant="grids.formRow"
                  rows={[1]}
                  columns={[1]}
                  gap={[3]}
                >
                  <Box>
                    <Label htmlFor="email">Reason for Contacting*</Label>
                    <Select
                      value={selectionState}
                      variant="input.base"
                      type="text"
                      name="reason"
                      id="reason"
                      required
                    >
                      <option value="schedule">Schedule Appointment</option>
                      <option value="order">Book/Product Order</option>
                      <option value="retreats">Retreats</option>
                      <option value="inquiry">General Inquiry</option>
                      <option value="other">Other</option>
                    </Select>
                  </Box>
                </Grid>
                <Grid
                  variant="grids.formRow"
                  rows={[1]}
                  columns={[1]}
                  gap={[3]}
                >
                  <Box>
                    <Label htmlFor="message">Message*</Label>
                    <Field
                      component="textarea"
                      name="message"
                      id="message"
                      rows={5}
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        backgroundColor: "hsl(0,0%,100%)",
                        borderColor: "hsl(0deg 0% 80% / 47%)",
                        border: "1px solid rgba(35,33,41,0.1)",
                        color: "hsl(10, 20%, 20%)",
                        transition: "all 250ms cubic-bezier(0.4,0,0.2,1)",
                        marginBottom: "1em",
                      }}
                      required
                    />
                    <ErrorMessage name="text" />
                  </Box>
                </Grid>
                <div data-netlify-recaptcha="true"></div>
                <Recaptcha
                  sitekey={process.env.SITE_RECAPTCHA_KEY}
                  render="explicit"
                  theme="dark"
                  verifyCallback={response => {
                    setToken(response)
                  }}
                  onloadCallback={() => {
                    console.log("done loading!")
                  }}
                />
                <Grid
                  gap={[2, 2, 3, 2]}
                  row={[1]}
                  columns={1}
                  sx={{ marginTop: ["1em"] }}
                >
                  <Button
                    variant="buttons.base"
                    type="submit"
                    sx={{ justifySelf: "flex-end" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Heading variant="medium" className="soft-faux-font">
                  Thank you
                </Heading>
                <Text as="p" variant="paragraphAlwaysWhite">
                  Look forward to speaking with you soon!
                </Text>
              </>
            )}
          </Flex>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
